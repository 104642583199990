html,
body {
  font-family: "Syne", sans-serif;
  background-color: white !important;
  // @apply text-text bg-offwhite;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Roobert-Regular";
  src: url("/fonts/Roobert/Roobert-Regular.woff2") format("woff2"),
    url("/fonts/Roobert/Roobert-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "RoobertTRIAL-Bold";
  src: url("/fonts/Roobert/RoobertTRIAL-Bold.woff2") format("woff2"),
    url("/fonts/Roobert/RoobertTRIAL-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// main {
//   transform: translateZ(0);
// }

.wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: calc(1180px + 40px);
  padding: 0 20px;
  @media (min-width: 800px) {
    padding: 0 60px;
    max-width: calc(1180px + 120px);
  }
}

// @mixin letter-spacing(spacing) {
//   letter-spacing: calc(-1em * 0.001 * #{$spacing});
// }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.h1 {
  font-size: responsive 27px 42px;
  // @include letter-spacing(-0.3);
  line-height: responsive 30px 47px;
  font-family: "RoobertTRIAL-Bold", sans-serif;
}

h2,
.h2 {
  font-size: responsive 19px 20px;
  // @include letter-spacing(-0.3);
  font-weight: 900 !important;
  font-family: "Roobert-Regular", sans-serif;
  line-height: responsive 20px 24px;
}

h3,
.h3 {
  font-size: responsive 16px 20px;
  font-family: "Syne", sans-serif;
  line-height: responsive 18px 24px;
  // @include letter-spacing(-0.3);
}

h4,
.h4 {
  font-size: 15px;
  // @include letter-spacing(-0.3);
}

h5,
.h5 {
  font-size: 13px;
  // @include letter-spacing(-0.3);
}

h6,
.h6 {
  font-size: 13px;
  // @include letter-spacing(-0.3);
}

p,
.p {
  font-size: 15px;
  font-family: "Syne", sans-serif;
  line-height: 1.64;
  // @include letter-spacing(-0.3);
  margin-bottom: 1em;
  &:last-of-type {
    margin-bottom: 0;
  }
}

a,
.a {
  font-family: "Syne", sans-serif;
}

input {
  font-family: "Syne", sans-serif;
}

span {
  font-family: "Syne", sans-serif;
}

.text-xs {
  font-size: 13px;
  // @include letter-spacing(-0.3);
}

img.lazyload:not([src]) {
  visibility: hidden;
}

.wysiwyg {
  p {
    line-height: 2em;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

a:not([class]) {
  text-decoration: underline;
  &:hover {
    opacity: 0.7;
  }
}

.main-footer a {
  white-space: nowrap;
}

input {
  border: 0 !important;
}
input:focus {
  border: 0 !important;
}

textarea:focus,
input:focus {
  outline: none !important;
  background: none;
}

*:focus {
  outline: none !important;
}

// .treat-div:hover {
//   transition: ease-in-out;
//   transition-duration: 700ms;
//   .p-treat {
//     transition-duration: 500ms;
//     font-size: 17px !important;
//   }
//   hr {
//     transition-duration: 500ms;
//     display: none;
//   }
// }

// .province-select {
//   overflow: hidden;
//   .flickity-viewport {
//     overflow: visible;
//   }
// }

.flickity-button {
  background: #92e3a9 !important;
  transition: all 0.2s ease-out;
  background-repeat: no-repeat !important;
  background-position: bottom !important;
  &.next {
    background: #92e3a9 !important;
  }
}

.carousel-cell {
  counter-increment: carousel-cell;
}

/* cell number */
.carousel-cell:before {
  display: none;
  text-align: center;
  content: counter(carousel-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}

.slider-nav {
  button:focus {
    background-color: #92e3a9;
  }
}

.shadow-box {
  box-shadow: 0 0 7px 7px #94d1a6be !important;
}

.accordian-label:after {
  content: "Expand for more info +";
  padding-left: 5px;
  display: grid;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

input:checked + label:after {
  content: "Close -";
  padding-top: 5px;
  padding-bottom: 5px;

  border-bottom: none;
}

.accordion__content {
  background: white;
  max-height: 0em;
  transition: all 0.4s cubic-bezier(0.865, 0.14, 0.095, 0.87);
}
input[name="panel"]:checked ~ .accordion__content {
  /* Get this as close to what height you expect */
  max-height: 50em;
  z-index: 100;
  left: 0;
}

.more-label:after {
  content: "Expand for more info +";
  padding-left: 5px;
  display: grid;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.more__content {
  background: white;
  max-height: 0em;
  transition: all 0.4s cubic-bezier(0.865, 0.14, 0.095, 0.87);
}
input[name="more-panel"]:checked ~ .more__content {
  /* Get this as close to what height you expect */
  max-height: 50em;
  z-index: 100;
  left: 0;
}

.disable-text-selection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button.active {
  position: relative;

  p {
    font-weight: 900 !important;
    letter-spacing: 2px;
  }
  @media only screen and (max-width: 1024px) {
    p {
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    background: repeating-linear-gradient(
      -45deg,
      #92e3a9a1 0px,
      #92e3a9a1 5px,
      #fff 5px,
      #fff 10px
    );
    border-bottom: solid 3px #92e3a9;
    @media only screen and (max-width: 1024px) {
      width: 80%;
      height: 80%;
    }
  }
}

.headroom--unpinned,
.headroom--pinned,
.headroom {
  margin: auto !important;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 250ms;
  padding-top: 15px !important;
  background-color: #f6fdfc !important;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  .logo {
    height: 50px !important;
  }
  .book-btn {
    height: 30px !important;
    width: 100px !important;
  }
  @media (max-width: 1024px) {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    padding-right: 20px;
    padding-left: 20px;
    .logo {
      height: 50px !important;
    }
  }
}
@media (max-width: 933px) {
  #runner {
    display: none;
  }
}
.office-setting {
  transform: translateX(130px);
}
#sticky-logo {
  display: none;
  width: 190px;
}
p.p-treat {
  min-height: 75px;
}
.headroom--not-top {
  .full-logo {
    display: none;
  }
  #sticky-logo {
    display: block;
  }
}
nav {
  max-width: 1500px;
}

.headroom--top {
  .logo {
    height: 75px !important;
  }
  @media (max-width: 1024px) {
    padding-right: 20px;
    padding-left: 20px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      img {
        height: 60px !important;
      }
    }
    li {
      font-size: 14px !important;
    }
  }
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 25px;
  background-color: rgba(0, 0, 0, 0) !important;
  .logo {
    @media (max-width: 1024px) {
      font-size: 23px !important;
    }
    font-size: 29px !important;
  }
  li {
    font-size: 15px !important;
  }
  .book-btn {
    height: 56px !important;
    width: 160px !important;
  }
}

.test {
  height: 160px;
  width: 170px;
  -webkit-clip-path: url(#shape);
  clip-path: url(#shape);
}
