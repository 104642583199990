/*! Flickity v2.3.0
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  margin-top: 290px;
  z-index: 200;
  position: absolute;
  border: none;
  svg {
    margin-top: 5px;
    height: 15px !important;
  }
  @media only screen and (max-width: 1024px) {
    margin-top: -100px;
    .flickity-prev-next-button.previous {
      left: -10px;
    }
    .flickity-prev-next-button.next {
      right: -10px;
    }
  }
}

.flickity-button:hover {
  border: solid 1px black;
  cursor: pointer;
}

.flickity-button:active {
  opacity: 1;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -55px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
  @media only screen and (max-width: 1024px) {
    position: absolute;
    width: 100%;
    bottom: -60px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
  }
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  @media only screen and (max-width: 1024px) {
    width: 10px;
    height: 10px;
    margin: 0 10px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
    border: none;
  }

  opacity: 0;
  height: 35px;
  width: 80px;
  margin: 0;
  border-radius: 0;
  margin: 1px;

  background: repeating-linear-gradient(
    -45deg,
    #92e3a9a1 0px,
    #92e3a9a1 5px,
    #fff 5px,
    #fff 10px
  );
  border-bottom: solid 3px #92e3a9;
}

.flickity-page-dots .dot.is-selected {
  opacity: 0.8;
}
