@import "tailwindcss/base";
@import "css/index.scss";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "css/flickity.scss";

.oh {
  overflow: hidden;
}

.featured .flickity-viewport {
  overflow: visible;
}

hr {
  transform: scaleY(0.5);
}

.featured .flickity-viewport {
  overflow: visible;
}

::selection {
  background: #d6a249;
  color: white;
}

.col2 {
  @media (min-width: 700px) {
    column-count: 2;
    column-gap: 40px;
  }
}

@keyframes price {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.price-open {
  animation: price 0.3s cubic-bezier(0.55, 0, 0.1, 1) forwards;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  margin-top: 50px;
  align-items: center;
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.tooltip .tooltiptext::after {
  position: absolute;
  transition: ease-out;
  transition: all;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

// .news-slider .flickity-viewport {
//   overflow: visible;
//   &:before {
//     content: "";
//     display: block;
//     position: absolute;
//     top: -100px;
//     bottom: -100px;
//     right: 100%;
//     width: 100%;
//     background-color: #2a558c;
//     z-index: 1;
//   }
// }

// .flickity-button {
//   z-index: 55;
// }
